<template>
  <div class="product-list" id="product-list">
    <v-slide-group 
      v-model="index"
      prev-icon="" 
      next-icon=""
    >
      <v-slide-item
        v-for="item in items"
        :key="item.shoppingCartItemId"
      >
        <template v-slot:default>
          <div
            class="product-list__item"
            :class="$root.isMobile ? '' : 'item--large'"
            @click="onProductsPreview()"
          >
            <div 
              class="item__image"
              :class="$root.isMobile ? '' : 'image--large'"
              :style="{'background-image': 'url(' + getItemImage(item) + ')'}"
            ></div>
            <div class="product-list__item-container">
              <div class="item__title text-wrap" :class="$root.isMobile ? '' : 'title--large'" v-html="getItemTitle(item)">
              </div>
              <div :class="'item__price ' + (isDiscountedPrice(item) ? 'price--discounted' : '')">
                <span :class="isDiscountedPrice(item) ? 'price__new' : ''">
                  {{ formatAmount(item.totalPrice, false) }}
                </span>
                <span v-show="isDiscountedPrice(item)" class="price__original" :class="lang !== 'ru' ? 'price__original--break' : ''">
                  {{ formatAmount(item.originalPrice, false) }}
                </span>
                <span v-show="item.promocodesCount" class="price__count">{{ item.promocodesCount + '&nbsp;' + $t('saleSnackbar/qty') }}</span>
              </div>
            </div>
          </div>
        </template>
      </v-slide-item>
    </v-slide-group>  

    <div 
      v-if="$root.isMobile && items.length < 4 && items.length > 2"
      class="product-list__item-placeholder"
      :style="{ 'width': placeholderWidth.toString() + 'px' }"
    >
    </div>

    <div 
      v-if="!$root.isMobile && items.length < 4"
      class="product-list__item-placeholder product-list__item-placeholder--large"
      :style="{ 'width': 'calc(100% - ' + placeholderWidth.toString() + 'px)' }"
    >
    </div>

    <div v-if="!$root.isMobile && index != 0 && items.length > 3" class="product-list__nav-icon nav-icon--left">
      <Button 
        @click="goPrevItem"
        variant="clear"
        view="light"
        shape="circle"
        size="lg"
      >
        <slot name="before">
          <span class="rr-button__icon rr-button__icon_auto">
            <base-icon width="47" height="47" viewBox="0 0 48 48">
              <icon-arrow-left fill="black" />
            </base-icon>
          </span>
        </slot>
      </Button>
    </div>
    <div v-if="!$root.isMobile && index != items.length-1 && items.length > 3" class="product-list__nav-icon nav-icon--right">
      <Button 
        @click="goNextItem"
        variant="clear"
        view="light"
        shape="circle"
        size="lg"
      >
        <slot name="before">
          <span class="rr-button__icon rr-button__icon_auto">
            <base-icon width="47" height="47" viewBox="0 0 48 48">
              <icon-arrow-right fill="black" />
            </base-icon>
          </span>
        </slot>
      </Button>
    </div>

    <v-dialog
      v-model="productDialog"
      :fullscreen="$root.isMobile"
      persistent
    >
      <ProductPreview
        :items_="products"
        :class="$root.isMobile ? '' : 'product-dialog--large'"
        @selected="onPhotoSelected" 
        @cancelled="productDialog=false" 
      />
    </v-dialog>

    <PhotoPreview
      :isOpened="photosDialog"
      :photos="photos"
      :checked="[]"
      :noCheckbox="true"
      :currentIndex="photosIndex"
      @cancelled="photosDialog=false"
    />

  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import PhotoPreview from '@/components/photo-preview/photo-preview.vue';
import ProductPreview from '@/components/product-preview/product-preview.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconArrowLeft from '@/components/icons/icon-arrow-left.vue';
import IconArrowRight from '@/components/icons/icon-arrow-right.vue';
import { ProductTypes } from '@/product-types';
import Common from '@/plugins/common';

export default {
  name: 'ProductList',
  props: {
    items: Array,
  },
  components: {
    PhotoPreview,
    ProductPreview,
    BaseIcon,
    IconArrowLeft,
    IconArrowRight,
  },
  data() {
    return {
      index: 0,
      productTypes: ProductTypes,
      productDialog: false,
      products: [],
      photosDialog: false,
      photos: [],
      photosIndex: 0,
      placeholderWidth: 0,      
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    productDialog() {
      if (this.productDialog) {
        document.documentElement.style.overflowY = 'hidden';
        document.body.style.overflowY = 'hidden';
      }
      if (!this.productDialog) {
        document.documentElement.style.overflowY = '';
        document.body.style.overflowY = '';
      }
    },
  },
  methods: {
    formatAmount(amount, onlyInteger = true) {
      return Common.formatAmount(amount, onlyInteger);
    },
    getItemImage(item) {
      const type = item.product.productType;
      if (type.length == 0) return '';

      if (type == this.productTypes.photos_with_me) {
        return '/img/all-photos.svg';
      } else if (type == this.productTypes.photosWithFrame) {
        return '/img/all-photos-in-frame.svg';
      } else if (type == this.productTypes.promocode) {
        return '/img/promocode.svg';
      } else {
        const url = item.product.photo.resources.preview;
        if (url.length == 0) return '';
        
        return url;
      }
    },
    getItemTitle(item) {
      const type = item.product.productType;
      if (type.length == 0) return '';

      if (type == this.productTypes.photos_with_me) {
        return this.$t('productPreview/productAllPhotos').toString();
      } else if (type == this.productTypes.photosWithFrame) {
        return item.product.title;
      } else if (type == this.productTypes.photo) {
        return this.$t('productPreview/productDigitalPhoto').toString();
      } else if (type == this.productTypes.promocode) {
        return item.product.title;
      } else {
        return this.$t('searchResults/productPhotoFrame').toString();
      }
    },
    isDiscountedPrice(item) {
      return item.originalPrice != item.totalPrice;
    },
    goPrevItem() {
      if (this.index - 4 >= 0) {
        this.index -= 4;
      } else {
        this.index = 0;
      }
    },
    goNextItem() {
      if (this.index + 4 < this.$props.items.length) {
        this.index += 4;
      } else {
        this.index = this.$props.items.length - 1;
      }
    },
    getItemPhotos(item, onlyPhotoWithMe = false) {
      const type = item.product.productType;
      if (type.length == 0) return [];

      if (onlyPhotoWithMe) {
        if (type != this.productTypes.photos_with_me && type != this.productTypes.photosWithFrame) {
          return [];
        }
      }

      let photos = [];
      if (type == this.productTypes.photos_with_me) {
        photos = Object.assign([], item.product.photos);
      } else if (type == this.productTypes.photosWithFrame) {
        photos = Object.assign([], item.product.photos);
      } else {
        photos = [ item.product.photo ];
      }
      if (photos.length == 0) return [];
      
      return photos;
    },
    onProductsPreview() {
      const products = [];
      const items = this.$props.items;

      for (let i = 0; i < items.length; i++) {
        let photos = this.getItemPhotos(items[i], true);
        products.push({ data: items[i], photos: photos });
      }
      this.photos = [];
      this.products = products;
      this.productDialog = true;
    },
    onPhotoSelected(payload) {
      this.photos = this.getItemPhotos(payload.item);
      this.photosIndex = payload.index;
      this.photosDialog = true;
    },
    updateWidth() {
      if (this.$root.isMobile) {
        
        const container = document.getElementById('product-list');
        if (container == null) return;
        
        this.placeholderWidth = container.offsetWidth - ((144 + 12) * this.$props.items.length);
        if (this.placeholderWidth < 0) this.placeholderWidth = 0;

      } else {
        this.placeholderWidth = (174 + 20) * this.$props.items.length;
      }
    }
  },
  created() {
    this.updateWidth();
    window.addEventListener('resize', () => {
        setTimeout(() => { this.updateWidth(); }, 250);
      }
    );
  },
  mounted() {
    this.updateWidth();
  }
};
</script>


<style lang="scss" scoped>
  @import "./product-list";
</style>
