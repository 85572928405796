export const PaymentMethods = [
  {
    id: "CreditCard",
    typeId: "CreditCard",
    title: "order/methodCreditCardTitle",
    description: "order/methodCreditCardDescription",
    disabledFor: ['Sporttiming'],
  },
  {
    id: "CreditCardSporttimingBel",
    typeId: "CreditCard",
    title: "order/methodCreditCardBelTitle",
    description: "order/methodCreditCardBelDescription",
    enabledFor: ['Sporttiming'],
  },
  {
    id: "CreditCardSporttimingRus",
    typeId: "CreditCard",
    title: "order/methodCreditCardRusTitle",
    description: "order/methodCreditCardRusDescription",
    enabledFor: ['Sporttiming'],
  },
  {
    id: "BusinessCard",
    typeId: "BusinessCard",
    title: "order/methodBusinessCardTitle",
    description: "order/methodBusinessCardDescription",
    warningText: "order/methodBusinessCardWarning",
    warningImage: "/img/payment/business-{0}.svg",
    disabledFor: ['Sporttiming'],
  },
];
