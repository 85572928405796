<template>
  <div class="list__payment" :style="showListOnly ? 'margin: 0;' : ''">
    <div v-if="!showListOnly" class="payment__title" :class="$root.isMobile ? '' : 'payment__title--large'">
      {{ $t('paymentOptions/title') }}
    </div>
    <div class="payment__list">
      <div v-for="(item, index) in paymentMethods" :key="item.id">
        <div class="list__item" :class="item.id != selectedMethod ? '' : 'item--selected'">
          <div class="item__text" :class="item.id != selectedMethod ? '' : 'text--selected'">
            {{ item.title }}
          </div>
          <div v-if="item.id == selectedMethod" class="item__description">
            {{ item.description }}

            <Alert
              v-if="item.id === 'CreditCardSporttimingBel'"
              state="warning"
              noAction
              style="margin-top: 16px;"
            >
              <template v-slot:text>
                {{ $t('ssb/cardTypeAlertText') }}
                <a class="link" style="color: var(--color-primary)" @click="() => $refs['3ds-modal'].open()">
                  <b>{{ $t('learnMore') }}</b>
                </a>
              </template>
            </Alert>

            <Alert
              v-else-if="item.id === 'CreditCardSporttimingRus'"
              state="success"
              noAction
              style="margin-top: 16px;"
              :text="$t('ssb/rusBanksAlertText')"
            ></Alert>

            <!-- TODO: Add 2 days after the start of sales -->
            <Alert
              v-if="false && item.id === 'CreditCardSporttimingBel'"
              state="warning"
              noAction
              style="margin-top: 16px;"
            >
              <template v-slot:text>
                <span v-html="$t('ssb/promoCodeAlertText')"></span> <a class="link" href="https://sporttiming.by/Login?ReturnUrl=%2Fminskhalfmarathon%2FPhoto%2FRegister" style="color: var(--color-primary); text-decoration: none; white-space: nowrap;" target="_blank">
                  <b>{{ $t('ssb/promoCodeAlertButton') }}</b>
                </a>
              </template>
            </Alert>

            <Alert
              v-else-if="item.id === 'BusinessCard'"
              state="warning"
              noAction
              noIcon
              class="business-card"
              style="margin-top: 16px;"
            >
              <template v-slot:text>
                <div v-html="item.warningText"></div>
                <div><img :src="item.warningImage" /></div>
              </template>
            </Alert>

          </div>
          <div v-if="item.id != selectedMethod" @click.stop="onSelect(index)" class="item__icon icon--unchecked">
            <div class="icon__empty">
            </div>
          </div>
          <div v-if="item.id == selectedMethod" @click.stop="onSelect(index)" class="item__icon">
            <base-icon width="25" height="25" viewBox="0 0 16 16">
              <icon-check-circle-s fill="var(--color-success)" />
            </base-icon>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="tenantName === 'Sporttiming'"
      ref="3ds-modal"
      name="3ds-modal"
      :title="$t('ssb/3dsModalTitle')"
      :subtitle="$t('ssb/3dsModalSubtitle')"
      isDefault
    >
      <template v-slot:icon>
        <base-icon width="48" height="48" viewBox="0 0 48 48">
          <IconProtect fill="var(--color-primary)"></IconProtect>
        </base-icon>
      </template>
      <div class="rr-modal__item">
        <div class="info-block">
          <div class="info-block__title">{{ $t('ssb/banksListTitle') }}</div>
          <ul>
            <li
              v-for="(bank, i) in supportedBanksList"
              :key="i"
            >
              {{ bank }}
            </li>
          </ul>
          <div class="info-block__action" @click="showAllSupportedBanks = !showAllSupportedBanks">
            <base-icon width="16" height="16" viewBox="0 0 24 24">
              <icon-plus fill="var(--color-warning)" v-show="!showAllSupportedBanks" />
              <icon-minus fill="var(--color-warning)" v-show="showAllSupportedBanks" />
            </base-icon>
            <span class="info-block__action-text">
              {{ showAllSupportedBanks ? $t('showLess') : $t('showMore') }}
            </span>
          </div>
        </div>
      </div>
      <div class="rr-modal__item" v-html="$t('ssb/3dsModalText')"></div>
      <div class="rr-modal__item">
        <Button
          variant="secondary"
          :text="$t('gotIt')"
          size="lg"
          style="border-radius: 8px;"
          @click="() => $refs['3ds-modal'].close()"
        ></Button>
      </div>
    </Modal>
  </div>  
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Modal from "@/components/modal/modal.vue";
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconCheckCircleS from "@/components/icons/icon-check-circle-s.vue";
import IconProtect from "@/components/icons/icon-protect.vue";
import IconPlus from "@/components/icons/icon-plus.vue";
import IconMinus from "@/components/icons/icon-minus.vue";

export default {
  name: 'PaymentOptions',
  props: {
    paymentMethods: Array,
    selectedMethod: String,
    showListOnly: Boolean,
  },
  components: {
    Modal,
    BaseIcon,
    IconCheckCircleS,
    IconProtect,
    IconPlus,
    IconMinus,
  },
  data() {
    return {
      showAllSupportedBanks: false,
      supportedBanks: [
        'Приорбанк',
        'Банк БелВЭБ',
        'R-Bank',
        'Статусбанк',
        'БНБ-Банк',
        'Банк Дабрабыт',
        'Paritetbank',
        'БСБ Банк',
        'Белинвестбанк',
        'БТА Банк',
        'RDB-Bank',
        'Технобанк',
        'Сбербанк',
        'Fransabank',
        'Белгазпромбанк',
        'Zepter Bank',
        'Белагропромбанк',
        'Альфа-Банк',
        'Беларусбанк',
        'Банк ВТБ Беларусь',
      ],
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['tenantName']),
    supportedBanksList() {
      return this.showAllSupportedBanks
          ? this.supportedBanks
          : this.supportedBanks.slice(0, 5);
    },
  },
  methods: {
    onSelect(index) {
      this.$emit("selected", index);
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./payment-options";
</style>
